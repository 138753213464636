import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60px;
  width: 60px;
  border-radius: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, z-index 0.5s ease-in-out 0.5s;
  &:before {
    content: '';
    height: 2px;
    width: 2px;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    animation: pulse-shadow 2s infinite;
  }
  svg {
    animation: spin 3s infinite;
    path {
      fill: rgba(51, 51, 51, 0.6);
    }
  }

  @keyframes pulse-shadow {
    0% {
      box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.6);
    }
    70% {
      box-shadow: 0 0 0 40px rgba(51, 51, 51, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(51, 51, 51, 0);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(90deg) scale(0.85);
    }
    70% {
      transform: rotate(110deg) scale(0.8);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`

export const style = {}

export default style
