import getTemplate from './email-template'
import Email from '../vendors/smtp'

export const sendEmail = (data, cb) => {
  //   console.log({email, ...data})
  Email.send({
    // SecureToken: '12f703e4-b46b-4c8b-9ee5-2a0551fd9971',
    SecureToken: 'dc6d3409-e61c-4ace-9b18-db4ee74be28a',
    // To: [data.email], // '22factorwholesale@gmail.com'
    To: [data.email, 'hello@22factor.com'], // '22factorwholesale@gmail.com'
    From: 'hello@22factor.com',
    Subject: 'Thank you for your Enquiry',
    Body: getTemplate(data),
    Attachments: [
      {
        name: 'Parts Details.jpg',
        data: data.partsImage,
      },
      {
        name: 'Garment Design.png',
        data: data.image,
      },
    ],
  }).then((message) => {
    if (message === 'OK') return cb(message, 'success')
    return cb(message, 'error')
  })
}
