import styled from 'styled-components'

export const Container = styled.div`
  min-width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.7);
  & > * {
    padding: 15px 20px;
  }
  .copyright {
  }
  .moreInfo {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > li {
      padding: 0 5px;
    }
  }
  @media (max-width: 450px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    display: none;
    /* .copyright {
      display: none;
    }
    .moreInfo {
      position: absolute;
      left: 50%;
      top: 80%;
      transform: translateX(-50%);
    } */
  }
`

export const style = {}

export default style
