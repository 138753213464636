const urlPrefix = './'
export default {
  defaultColors: {
    count: 13,
    cashmere: '03',
    cotton: '08',
  },
  yarnAliases: {
    cashmere: 'Cashmere',
    cotton: 'Premium Cotton',
  },
  defaultState: {
    yarn: 'cashmere',
    parts: {
      neck: {
        noding: '2x2',
        color: '03',
      },
      body_upper: {
        noding: '1x1',
        color: '03',
        alias: 'Upper Body',
      },
      body_lower: {
        noding: '1x1',
        color: '03',
        alias: 'Lower Body',
      },
      left_sleeve: {
        noding: '1x1',
        color: '03',

        texCoordUnit: 1,
      },
      right_sleeve: {
        noding: '1x1',
        color: '03',

        texCoordUnit: 1,
      },
    },
  },
  yarns: {
    cashmere: {
      '1x1': {
        cavity: require(`${urlPrefix}cashmere_2x2_cavity.jpg`),
        normal: require(`${urlPrefix}cashmere_2x2_normal.jpg`),
        '01': require(`${urlPrefix}cashmere_1x1_01_color.jpg`),
        '02': require(`${urlPrefix}cashmere_1x1_02_color.jpg`),
        '03': require(`${urlPrefix}cashmere_1x1_03_color.jpg`),
        '04': require(`${urlPrefix}cashmere_1x1_04_color.jpg`),
        '05': require(`${urlPrefix}cashmere_1x1_05_color.jpg`),
        '06': require(`${urlPrefix}cashmere_1x1_06_color.jpg`),
        '07': require(`${urlPrefix}cashmere_1x1_07_color.jpg`),
        '08': require(`${urlPrefix}cashmere_1x1_08_color.jpg`),
        '09': require(`${urlPrefix}cashmere_1x1_09_color.jpg`),
        '10': require(`${urlPrefix}cashmere_1x1_10_color.jpg`),
        '11': require(`${urlPrefix}cashmere_1x1_11_color.jpg`),
        '12': require(`${urlPrefix}cashmere_1x1_12_color.jpg`),
        '13': require(`${urlPrefix}cashmere_1x1_13_color.jpg`),
      },
      '2x2': {
        cavity: require(`${urlPrefix}cashmere_2x2_cavity.jpg`),
        normal: require(`${urlPrefix}cashmere_2x2_normal.jpg`),
        '01': require(`${urlPrefix}cashmere_2x2_01_color.jpg`),
        '02': require(`${urlPrefix}cashmere_2x2_02_color.jpg`),
        '03': require(`${urlPrefix}cashmere_2x2_03_color.jpg`),
        '04': require(`${urlPrefix}cashmere_2x2_04_color.jpg`),
        '05': require(`${urlPrefix}cashmere_2x2_05_color.jpg`),
        '06': require(`${urlPrefix}cashmere_2x2_06_color.jpg`),
        '07': require(`${urlPrefix}cashmere_2x2_07_color.jpg`),
        '08': require(`${urlPrefix}cashmere_2x2_08_color.jpg`),
        '09': require(`${urlPrefix}cashmere_2x2_09_color.jpg`),
        '10': require(`${urlPrefix}cashmere_2x2_10_color.jpg`),
        '11': require(`${urlPrefix}cashmere_2x2_11_color.jpg`),
        '12': require(`${urlPrefix}cashmere_2x2_12_color.jpg`),
        '13': require(`${urlPrefix}cashmere_2x2_13_color.jpg`),
      },
    },
    cotton: {
      '1x1': {
        cavity: require(`${urlPrefix}cotton_1x1_cavity.jpg`),
        normal: require(`${urlPrefix}cotton_1x1_normal.jpg`),
        '01': require(`${urlPrefix}cotton_1x1_01_color.jpg`),
        '02': require(`${urlPrefix}cotton_1x1_02_color.jpg`),
        '03': require(`${urlPrefix}cotton_1x1_03_color.jpg`),
        '04': require(`${urlPrefix}cotton_1x1_04_color.jpg`),
        '05': require(`${urlPrefix}cotton_1x1_05_color.jpg`),
        '06': require(`${urlPrefix}cotton_1x1_06_color.jpg`),
        '07': require(`${urlPrefix}cotton_1x1_07_color.jpg`),
        '08': require(`${urlPrefix}cotton_1x1_08_color.jpg`),
        '09': require(`${urlPrefix}cotton_1x1_09_color.jpg`),
        '10': require(`${urlPrefix}cotton_1x1_10_color.jpg`),
        '11': require(`${urlPrefix}cotton_1x1_11_color.jpg`),
        '12': require(`${urlPrefix}cotton_1x1_12_color.jpg`),
        '13': require(`${urlPrefix}cotton_1x1_13_color.jpg`),
      },
      '2x2': {
        cavity: require(`${urlPrefix}cotton_1x1_cavity.jpg`),
        normal: require(`${urlPrefix}cotton_1x1_normal.jpg`),
        '01': require(`${urlPrefix}cotton_2x2_01_color.jpg`),
        '02': require(`${urlPrefix}cotton_2x2_02_color.jpg`),
        '03': require(`${urlPrefix}cotton_2x2_03_color.jpg`),
        '04': require(`${urlPrefix}cotton_2x2_04_color.jpg`),
        '05': require(`${urlPrefix}cotton_2x2_05_color.jpg`),
        '06': require(`${urlPrefix}cotton_2x2_06_color.jpg`),
        '07': require(`${urlPrefix}cotton_2x2_07_color.jpg`),
        '08': require(`${urlPrefix}cotton_2x2_08_color.jpg`),
        '09': require(`${urlPrefix}cotton_2x2_09_color.jpg`),
        '10': require(`${urlPrefix}cotton_2x2_10_color.jpg`),
        '11': require(`${urlPrefix}cotton_2x2_11_color.jpg`),
        '12': require(`${urlPrefix}cotton_2x2_12_color.jpg`),
        '13': require(`${urlPrefix}cotton_2x2_13_color.jpg`),
      },
    },
  },
}
