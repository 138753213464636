export default ({
  name,
  email,
  company,
  country,
  quantity,
  salesChannel,
  comments,
  partsImage,
  image,
  yarnName,
  styleName,
  colorDetails,
}) => {
  return `
    <table
      class="m_4726344385676614233body"
      style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse"
    >
      <tbody>
        <tr>
          <td style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
            <table
              class="m_4726344385676614233header m_4726344385676614233row"
              style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px"
            >
              <tbody>
                <tr>
                  <td
                    class="m_4726344385676614233header__cell"
                    style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                  >
                    <center>
                      <table
                        class="m_4726344385676614233container"
                        style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto"
                      >
                        <tbody>
                          <tr>
                            <td style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
                              <table
                                class="m_4726344385676614233row"
                                style="width:100%;border-spacing:0;border-collapse:collapse"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="m_4726344385676614233shop-name__cell"
                                      style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                                    >
                                      <img
                                        src="https://ci3.googleusercontent.com/proxy/uL9vG_wlv1Jd0Yo_arUhyBNXw09XBC-ZYwpCB53xMz5lynu5n7Gl9vByWKVh5oWpNbsm7VFq6Odtsojc4Kqd2k4wnW9rshn_kdmFYVDdafBnaU-PYAxcIF31xsdsdyWl2JOz_5zmy427tzAeARwcYD_JIN2gyiOoEBxaEj49U581yS0FG5b0SA5DYIdkM0M=s0-d-e1-ft#https://cdn.shopify.com/s/files/1/0018/8504/4806/email_settings/22_factor_logo_a0ef0a47-790c-4686-afd0-c1a01f5a7826.png?2388"
                                        alt="22factor"
                                        width="100"
                                        class="CToWUd"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="m_4726344385676614233row m_4726344385676614233content"
              style="width:100%;border-spacing:0;border-collapse:collapse"
            >
              <tbody>
                <tr>
                  <td
                    class="m_4726344385676614233content__cell"
                    style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;padding-bottom:40px'
                  >
                    <center>
                      <table
                        class="m_4726344385676614233container"
                        style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto"
                      >
                        <tbody>
                          <tr>
                            <td style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
                              <h2 style="font-weight:normal;font-size:24px;margin:0 0 10px"> Dear ${name}, </h2>
                              <p style="color:#777!important;line-height:150%;font-size:16px;margin:0">
                                Thank you for your enquiry! <br />
                                Please find your submitted details below. We shall review and get back to you soon!
                                <br />
                                <br />
                                <style>.datagrid table { border-collapse: collapse; text-align: left; width: 100%; } .datagrid {font: normal 12px/150% Arial, Helvetica, sans-serif; background: #fff; overflow: hidden; }.datagrid table td, .datagrid table th { padding: 6px 10px; }.datagrid table tbody td { color: #7D7D7D!important; font-size: 14px;border-bottom: 1px solid #E1EEF4;font-weight: normal; }.datagrid table tbody td:first-child { border-left: none; }.datagrid table tbody tr:last-child td { border-bottom: none; }</style>
                                <div class="datagrid">
                                  <table>
                                    <tbody>
                                      <tr><td><b>Name</b></td><td>${name || '---'}</td></tr>
                                      <tr><td><b>Email</b></td><td>${email || '---'}</td></tr>
                                      <tr><td><b>Company</b></td><td>${company || '---'}</td></tr>
                                      <tr><td><b>Country</b></td><td>${country || '---'}</td></tr>
                                      <tr><td><b>Estimated Quantity</b></td><td>${quantity || '---'}</td></tr>
                                      <tr><td><b>Sales channel</b></td><td>${salesChannel || '---'}</td></tr>
                                      <tr><td><b>Comments</b></td><td>${comments || '---'}</td></tr>
                                      <tr><td><b>Style</b></td><td>${styleName || '---'}</td></tr>
                                      <tr><td><b>Yarn</b></td><td>${yarnName || '---'}</td></tr>
                                      <tr><td><b>Colors</b></td><td>${colorDetails || '---'}</td></tr>
                                    </tbody>
                                  </table>
                                </div>
                                <br />
                                <br />
                                <h3 style="font-weight:normal;font-size:12px;margin:0 0 10px">Please check attachments for parts details and garment design.</h3>
                                <br />
                                <br />
                                We will contact you back soon! <br />
                                22 Factor
                              </p>
                              <table
                                class="m_4726344385676614233row m_4726344385676614233actions"
                                style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      class="m_4726344385676614233actions__cell"
                                      style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                                    >
                                      <table
                                        class="m_4726344385676614233button m_4726344385676614233main-action-cell"
                                        style="border-spacing:0;border-collapse:collapse;float:left;margin-right:15px"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              class="m_4726344385676614233link__cell"
                                              style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                                              align="center"
                                            >
                                              <a
                                                href="https://22factor.com"
                                                class="m_4726344385676614233link__text"
                                                style="font-size:16px;text-decoration:none;color:#dfafa1!important"
                                                target="_blank"
                                                data-saferedirecturl="https://www.google.com/url?q=https://22factor.com&amp;source=gmail&amp;ust=1563787461097000&amp;usg=AFQjCNGls93KOfcIG9mwoLcfglCTstqH_w"
                                              >
                                                Visit our store
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="m_4726344385676614233row m_4726344385676614233footer"
              style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5!important;border-top-style:solid"
            >
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    class="m_4726344385676614233footer__cell"
                    style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;padding:35px 0'
                  >
                    <center>
                      <table
                        class="m_4726344385676614233container"
                        style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto"
                      >
                        <tbody>
                          <tr>
                            <td style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
                              <p
                                class="m_4726344385676614233disclaimer__subtext"
                                style="color:#999!important;line-height:150%;font-size:14px;margin:0"
                              >
                                If you have any questions, reply to this email or contact us at 
                                <a
                                  href="mailto:hello@22factor.com"
                                  style="font-size:14px;text-decoration:none;color:#dfafa1!important"
                                  target="_blank"
                                >
                                  hello@22factor.com
                                </a>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </center>
                  </td>
                </tr>
                <tr>
                  <td
                    style='width:50%;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                    align="right"
                  >
                    <a
                      href="https://www.facebook.com/22-factor-270388827017478/"
                      style="font-size:16px;text-decoration:none;color:#dfafa1!important"
                      target="_blank"
                      data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/22-factor-270388827017478/&amp;source=gmail&amp;ust=1563787461097000&amp;usg=AFQjCNH4hWKErQwcSNHlNFDbkV92ICSQHg"
                    >
                      <img
                        src="https://ci3.googleusercontent.com/proxy/DpF4GQHBUdsBldnrd_iausdYnpn9mLJnxAMS35MSMUDRcXyJ5AEEEJybmtoRLFCcAKPVB-Iudhdg4XbLqa3EPa1nwc_yh8OV-X3h79pyPmHPZtISoSSwwx92cPE5TI1XashW4uJlTfOKobXDr0NxIaAKLJizQQkGwHZ0te-fpWWELvw-=s0-d-e1-ft#https://cdn.shopify.com/s/files/1/0018/8504/4806/files/Screen_Shot_2018-10-29_at_17.36.34.png?5484661410959344638"
                        class="CToWUd"
                      />
                    </a>
                  </td>
                  <td
                    style='width:50%;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
                    align="left"
                  >
                    <a
                      href="https://instagram.com/22factor"
                      style="font-size:16px;text-decoration:none;color:#dfafa1!important"
                      target="_blank"
                      data-saferedirecturl="https://www.google.com/url?q=https://instagram.com/22factor&amp;source=gmail&amp;ust=1563787461097000&amp;usg=AFQjCNGTnGA9k6SE9F4QK-Bsh_-2z0-dVw"
                    >
                      <img
                        src="https://ci4.googleusercontent.com/proxy/U8tdU8FOeSTxTvNzdweNMv7uls5Q4XjFxfH6TsbThZalXI72Va1M5tZBUEc7uxGqW73x5d0QtyAyrPhtgPCLc8JZpiDZTo7fEt5IiKb60b7kJNwCO_hj_Mkqo6t-k1cnTfHLNJ7wKG718ZAwNQwy83ygqBLR5oONdhLYuwL3a7YINFie=s0-d-e1-ft#https://cdn.shopify.com/s/files/1/0018/8504/4806/files/Screen_Shot_2018-10-29_at_17.36.38.png?5484661410959344638"
                        class="CToWUd"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <img
              src="https://ci3.googleusercontent.com/proxy/uy_YJEmylUvjof7YRGh4UuCW_WGXqiXe9bytoPd4Du6njxZsKBvqjDPCwpvLrf2DlakVXE-EWg6klYBQ-ghUY3IeP5ICxP__dkoCzkhYqlOBZuoU9i0d5bQRiAPOY1XXlTly4A1Fjpl7XbNaCLO5FDSAFCPkfTRrLYE-EP4kYE3M-JqMHVMsLIeci1dGszEM0BKVPdxwvQt1IglaxA=s0-d-e1-ft#https://cdn.shopify.com/s/assets/themes_support/notifications/spacer-1a26dfd5c56b21ac888f9f1610ef81191b571603cb207c6c0f564148473cab3c.png"
              class="m_4726344385676614233spacer CToWUd"
              height="1"
              style="min-width:600px;height:0"
            />
          </td>
        </tr>
      </tbody>
    </table>
  `
}
