import React from 'react'

import Model from '../../components/Model'
import Configurator from '../../components/Configurator'
import { ReactComponent as Pointer } from '../../components/Configurator/pointer.svg'
import Subheader from './components/Subheader'
import Footer from './components/Footer'
import FooterAside from './components/FooterAside'
import LogoLoading from './components/LogoLoading'
import { Container } from './App.styles'

import textures from '../../components/Model/textures'

function is_touch_device() {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  var mq = function(query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      activeTab: 1,
      apiLoaded: false,
      modelKey: (+new Date()).toString(16),
      showHint: true,
      materials: textures.aries.defaultState,
      // materials: {
      //   yarn: 'cashmere',
      //   neck: {
      //     noding: '2x2',
      //     color: '03',
      //   },
      //   body_upper: {
      //     noding: '1x1',
      //     color: '03',
      //   },
      //   body_lower: {
      //     noding: '1x1',
      //     color: '03',
      //   },
      //   left_sleeve: {
      //     noding: '1x1',
      //     color: '03',

      //     texCoordUnit: 1,
      //   },
      //   right_sleeve: {
      //     noding: '1x1',
      //     color: '03',

      //     texCoordUnit: 1,
      //   },
      // },
      models: {
        1: {
          name: 'Aries',
          id: 'aries',
          uid: '0d1ef34e6de64628b83faa380293756a',
          defaultYarn: 'cashmere',
        },
        2: {
          name: 'Lynx',
          id: 'lynx',
          uid: '810566d8feef440aa77027be208f4f5a',
          defaultYarn: 'cashmere',
        },
        3: {
          name: 'Indus',
          id: 'indus',
          uid: 'a2a517f3ceb04700ba104533d16760f1',
          defaultYarn: 'cashmere',
        },
      },
      activeTarget: null,
    }
  }

  componentDidMount() {
    // To stop rotation on click
    const myConfObj = {
      iframeMouseOver: false,
    }

    // // eslint-disable-next-line no-restricted-globals
    window.focus()
    const listener = window.addEventListener('blur', () => {
      if (document.activeElement === document.getElementById('apiFrame')) {
        this.setState({ showHint: false })
      }
      window.removeEventListener('blur', listener)
    })

    document.getElementById('apiFrame').addEventListener('mouseover', () => {
      myConfObj.iframeMouseOver = true
    })
    document.getElementById('apiFrame').addEventListener('mouseout', () => {
      myConfObj.iframeMouseOver = false
    })
  }

  updateModel = (activeTab) => {
    this.setState(
      {
        modelKey: (+new Date()).toString(16),
        activeTab,
        apiLoaded: false,
        materials: this.getDefaultMaterialsByTab(activeTab),
        activeTarget: null,
        api: null,
      },
      () => this.updateMaterials()
    )
  }

  updateYarn = (yarn) => {
    const { models, activeTab } = this.state
    const activeModel = models[activeTab]
    const newMaterials = {
      ...this.getDefaultMaterials({
        style: activeModel.id,
        defaultYarn: yarn,
      }),
      key: (+new Date()).toString(16),
    }

    this.setState({
      materials: newMaterials,
      activeTarget: null,
    })
  }

  updatePartColor = ({ part, ...details }) => {
    const { materials } = this.state
    const newMaterials = { ...materials, parts: { ...materials.parts } }
    newMaterials.parts[part] = details
    newMaterials.partToPaint = part
    this.setState(
      {
        materials: newMaterials,
      },
      () => {}
    )
  }

  updateGlobalColor = (color) => {
    const { materials } = this.state
    const newMaterials = { ...materials, parts: { ...materials.parts } }
    Object.keys(materials.parts).forEach((k) => {
      newMaterials.parts[k].color = color
    })
    this.setState(
      {
        materials: { ...newMaterials, key: (+new Date()).toString(16) },
      },
      () => {}
    )
  }

  updateMultipleColors = (parts, color) => {
    const { materials } = this.state
    const newMaterials = { ...materials, parts: { ...materials.parts } }
    parts.forEach((k) => {
      newMaterials.parts[k].color = color
    })
    this.setState(
      {
        materials: { ...newMaterials, key: (+new Date()).toString(16) },
      },
      () => {}
    )
  }

  updateTexutures = ({ targets = [], noding }) => {
    const { materials } = this.state
    const newMaterials = { ...materials, parts: { ...materials.parts } }
    Object.keys(materials.parts).forEach((k) => {
      if (targets.includes(k)) {
        newMaterials.parts[k].noding = noding
      }
    })
    this.setState(
      {
        materials: { ...newMaterials, key: (+new Date()).toString(16) },
      },
      () => {}
    )
  }

  getDefaultMaterialsByTab = (activeTab = this.state.activeTab) => {
    const { models } = this.state
    const activeModel = models[activeTab]
    const { defaultYarn } = activeModel
    return this.getDefaultMaterials({ style: activeModel.id, defaultYarn })
  }

  getDefaultMaterials = ({ style = 'aries', defaultYarn }) => {
    const materials = { ...textures[style].defaultState, parts: { ...textures[style].defaultState.parts } }
    materials.yarn = defaultYarn

    const colorByPart = textures[style].defaultColors[defaultYarn + 'ByPart']
    const color = textures[style].defaultColors[defaultYarn]
    if (colorByPart) {
      Object.keys(materials.parts).forEach((k) => {
        materials.parts[k].color = colorByPart[k] || color
      })
    } else {
      Object.keys(materials.parts).forEach((k) => {
        materials.parts[k].color = color
      })
    }

    return materials
  }

  updateMaterials = (config) => {
    if (!config) {
      config = this.getDefaultMaterialsByTab()
    }
    this.setState({
      materials: config,
    })
  }

  resetDesign = () => {
    const { activeTab } = this.state
    this.setState({
      materials: {
        ...this.getDefaultMaterialsByTab(activeTab),
        key: (+new Date()).toString(16),
      },
      activeTarget: null,
    })
  }

  selectTarget = (activeTarget) => {
    this.setState({ activeTarget })
  }

  render() {
    const { modelKey, activeTab, models, apiLoaded, materials, activeTarget, api, showHint } = this.state
    const activeModel = models[activeTab]
    return (
      <Container onClick={() => this.setState({ showHint: false })}>
        <header className="header">
          <div className="logo">
            <span>22factor</span>
          </div>
        </header>
        <Subheader
          activeTab={activeTab}
          onSwitch={this.updateModel}
          selectedMaterials={materials}
          resetDesign={this.resetDesign}
          models={models}
          api={api}
          model={activeModel}
        />

        <div className="main">
          <Model
            key={modelKey}
            uid={activeModel.uid}
            model={activeModel}
            onApiLoaded={(api) =>
              this.setState({ apiLoaded: true, api }, () => {
                api.addEventListener('click', () => {
                  this.setState({ showHint: false })
                })
              })
            }
            materials={materials}
            onMaterialChange={this.updateMaterials}
            onMaterialSelect={(activeTarget) => this.setState({ activeTarget })}
            selectedTarget={apiLoaded && activeTarget}
          />
          <Configurator
            changeYarn={this.updateYarn}
            changeColor={this.updatePartColor}
            changeGlobalColor={this.updateGlobalColor}
            changeMultipleColors={this.updateMultipleColors}
            changeGroupTextures={this.updateTexutures}
            currentMaterials={apiLoaded && materials}
            selectedTarget={apiLoaded && activeTarget}
            model={activeModel}
            selectTarget={this.selectTarget}
          />
          <div className="logoLoader">
            <LogoLoading show={!apiLoaded} />
          </div>
          {apiLoaded && showHint && (
            <div className="hintWrapper">
              <div className="hint">
                <Pointer />
                <div className="button">
                  {is_touch_device() ? (
                    <span>
                      Touch an Area to Design <br /> Zoom by Pinching with Two Fingers <br /> Rotate by Touch and Drag
                    </span>
                  ) : (
                    <span>
                      Click on an Area to Design <br /> Zoom Using Mousewheel <br /> Rotate by Click and Drag
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer api={api} />
        <FooterAside />
      </Container>
    )
  }
}

export default App
