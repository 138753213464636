import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './Subheader.styles'
import SubmissionModal from '../SubmissionModal'

class Subheader extends React.Component {
  state = {
    showCart: false,
  }

  showCart = () => {
    this.setState({
      showCart: true,
    })
  }

  hideCart = (dataToSave) => {
    setTimeout(
      () =>
        this.setState({
          showCart: false,
          dataToSave,
        }),
      0
    )
  }

  render() {
    const { activeTab, onSwitch, selectedMaterials, resetDesign, models, model, api } = this.props
    const { showCart, dataToSave } = this.state

    return (
      <Container className="subheader">
        <div
          className="left button"
          onClick={resetDesign}
          style={{ opacity: api ? 1 : 0, pointerEvents: api ? 'all' : 'none' }}
        >
          Start Over
        </div>
        <div className="center">
          <div className={`tabs active${activeTab}`}>
            {[...Array(3)].map((_, i) => (
              <div
                className={`tab${i + 1} ${i + 1 === activeTab ? 'active' : ''}  button`}
                role="presentation"
                onClick={() => onSwitch(i + 1)}
              >
                {models && models[i + 1] ? models[i + 1].name : `STYLE ${i + 1}`}
              </div>
            ))}
            <div className="underline" />
          </div>
        </div>
        <div
          className="right button"
          onClick={this.showCart}
          style={{ opacity: api ? 1 : 0, pointerEvents: api ? 'all' : 'none' }}
        >
          Submit Your Enquiry
        </div>
        {showCart && <SubmissionModal initialData={dataToSave} api={api} onClose={this.hideCart} model={model} materials={selectedMaterials} />}
      </Container>
    )
  }
}

Subheader.propTypes = {}

export default Subheader
