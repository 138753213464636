import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  /* position: absolute; */
  width: 94%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 3%;
  /* background-color: rgba(255, 255, 255, 0.7); */
  box-shadow: 0 -1px #e7e7e7 inset;
  position: relative;
  &,
  & > * {
    z-index: 100;
  }
  .tabs {
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 270px;
    line-height: 24.75px;
    font-size: 15px;
    .underline {
      width: 20%;
      margin: 0 6.66%;
      height: 2px;
      position: absolute;
      background: #333;
      bottom: -3px;
      padding: 0;
      transition: left 0.5s ease-in-out;
      z-index: 1;
      border: none;
    }
    &.active1 .underline {
      left: 0%;
    }
    &.active2 .underline {
      left: 33.33%;
    }
    &.active3 .underline {
      left: 66.66%;
    }
    & > * {
      display: flex;
      flex: 1;
      padding: 5px;
      justify-content: center;
      /* border-bottom: 1px solid rgba(50, 50, 50, 0.5); */
      color: rgba(50, 50, 50, 0.8);
      &:hover {
        color: #687aa9;
      }
    }
  }
  .left,
  .right {
    margin-top: -140px;
  }

  @media (max-width: 768px) {
    & {
      /* justify-content: center; */
      /* top: 80px; */
      margin-top: -25px;
    }
    .left,
    .right {
      margin-top: -50px;
    }
    .center {
      align-self: flex-end;
      margin-bottom: 3px;
      .tabs {
        line-height: 14px;
      }
    }
    .button {
      font-size: 12px !important;
    }
    .tabs {
      width: 190px;
      top: 12px;
    }
  }
`

export { Container }
