import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { style, Container } from './Footer.style'

class Footer extends Component {
  state = {
    isRotating: false,
  }

  componentDidMount() {
    // To stop rotation on click
    const myConfObj = {
      iframeMouseOver: false,
    }
    window.addEventListener('blur', () => {
      if (myConfObj.iframeMouseOver) {
        this.toggleRotation(false)
      }
    })

    document.getElementById('apiFrame').addEventListener('mouseover', () => {
      myConfObj.iframeMouseOver = true
    })
    document.getElementById('apiFrame').addEventListener('mouseout', () => {
      myConfObj.iframeMouseOver = false
    })
  }

  toggleRotation = (value = !this.state.isRotating) => {
    const { api } = this.props
    const isRotating = value
    if (api) {
      this.setState(
        {
          isRotating,
        },
        () => {
          if (isRotating) {
            api.startRotationLeft()
            // api.addEventListener('click', () => this.toggleRotation(false))
          } else {
            api.stopRotationLeft()
          }
        }
      )
    }
  }

  render() {
    const { api } = this.props
    const { isRotating } = this.state

    return (
      <Container>
        <div className="navigation">
          {api && (
            <div className="navigation">
              <div className="buttons">
                <div
                  className="arrow arrowLeft"
                  onClick={() => {
                    // this.toggleRotation(false)
                    api.goToPrevAnnotation('deg', true)
                  }}
                >
                  {'<'}
                </div>
                <div
                  className={`but but-rotate ${isRotating ? 'rotating' : ''}`}
                  onClick={() => api.gotoAnnotation(0)}
                  // onClick={() => this.toggleRotation()}
                />
                <div
                  className="arrow arrowRight"
                  onClick={() => {
                    // this.toggleRotation(false)
                    api.goToNextAnnotation('deg', true)
                  }}
                >
                  {'>'}
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    )
  }
}

Footer.propTypes = {}

export default Footer
