import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 80%;
  box-shadow: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  pointer-events: none;
  .divider {
    height: 1px;
    width: 160px;
    background-color: #333;
    margin: 40px auto;
    &.minimal {
      margin: 7px auto;
    }
  }
  & > div {
    text-align: center;
    margin: auto 0;
  }
  .button {
    pointer-events: all;
  }
  .right {
    width: 160px;
    .divider {
      margin-top: 10px;
    }
    .bodyPart {
      &.active {
        color: #333;
      }
    }
    .colorsBlock {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 170px;
      margin-left: -5px;
      text-align: center;
      &.suffixed::after {
        content: '';
        flex: auto;
        margin: 5px;
      }
      .colorButton {
        position: relative;
        pointer-events: all;
        height: 25px;
        width: 25px;
        margin: 8.75px;
        border-radius: 100%;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid rgba(50, 50, 50, 0.3); */
        * {
          transition: all 0.1s ease-in-out;
        }
        .texture {
          background-size: 300%;
          background-position: center;
          height: 25px;
          width: 25px;
          border-radius: 100%;
        }
        &:after {
          content: '';
          width: 21px;
          height: 21px;
          position: absolute;
          border: 2px solid rgba(50, 50, 50, 0.3);
          border-radius: 100%;
          z-index: 1;
          top: 0px;
          left: 0px;
        }
        &.active {
          .texture {
            height: 17px;
            width: 17px;
            background-size: 700%;
          }
          &:after {
            content: '';
            border-color: #bbb;
          }
        }
      }
    }
    .texturesBlock {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-left: -5px;
      text-align: center;
      &.suffixed::after {
        content: '';
        flex: auto;
        margin: 5px;
      }
      .textureButton {
        position: relative;
        pointer-events: all;
        height: 35px;
        width: 35px;
        margin: 7px;
        /* border-radius: 100%; */
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        align-items: center;
        /* border: 1px solid rgba(50, 50, 50, 0.3); */
        * {
          transition: all 0.1s ease-in-out;
        }
        .texture {
          background-size: 100%;
          background-position: center;
          height: 35px;
          width: 35px;
        }
        &:after {
          content: '';
          width: 31px;
          height: 31px;
          position: absolute;
          border: 2px solid rgba(50, 50, 50, 0.3);
          z-index: 1;
          top: 0px;
          left: 0px;
        }
        &.active {
          .texture {
            height: 27px;
            width: 27px;
            background-size: 150%;
          }
          &:after {
            content: '';
            border-color: #bbb;
          }
        }
      }
    }
  }

  @media (min-width: 769px) {
    .moreInfo {
      display: none;
    }
    .colorButton:hover {
      .texture {
        height: 17px;
        width: 17px;
        background-size: 500%;
      }
      &:after {
        content: '';
        border-color: #bbb;
      }
    }
    .colorsBlockWrapper {
      height: 160px;
    }
    .right {
      padding-top: 76px;
    }
    .texturesBlock {
      padding: 0 20%;
      margin-bottom: 5px;
      height: 100px;
    }
  }
  @media (max-width: 768px) {
    justify-content: flex-end;
    flex-direction: column;
    transform: translateX(-50%);
    bottom: 0px;
    width: 100%;
    top: initial;
    .bodyPartsWrapper {
      overflow: auto;
      max-width: 100%;
      pointer-events: all;
    }
    .bodyPartsBlock {
      min-width: max-content;
      align-items: center;
      justify-content: center;
      margin: 0;
      flex-wrap: nowrap;
    }
    .bodyPart {
      display: inline-block;
      font-size: 12px;
      padding: 3px 7px;
      &.button:hover {
        color: #939393;
      }
      &.button.active {
        color: #333;
      }
    }
    & > div:not(.right) {
      background-color: rgba(255, 255, 255, 0.7);
    }
    & > div,
    .colorsBlock {
      margin: 0;
      width: 100% !important;
    }
    .colorsBlockWrapper {
      overflow: auto;
      max-width: 100%;
      pointer-events: all;
    }
    .right {
      background-color: rgba(255, 255, 255, 1);
      height: 65px;
      .hint {
        margin-top: 5px;
      }
      .colorsBlock {
        min-width: min-content;
        align-items: center;
        justify-content: center;
        margin: 0;
        flex-wrap: nowrap;
        &::after {
          content: none !important;
        }
      }
    }
    &.lynx {
      .right {
        height: 140px;
      }
      .bodyPart.heading {
        margin-right: 20px;
      }
    }
    .divider {
      display: none;
    }
    .left {
      display: flex;
      flex-direction: row;
      justify-content: center;
      box-shadow: 0 -1px #e7e7e7 inset;
      & > div {
        padding: 10px 20px;
      }
      .button {
        font-size: 18px;
      }
    }
    .texturesBlock {
      height: 50px;
    }
    .left {
      position: relative;
      .moreInfo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -35px;
        pointer-events: all;
        z-index: 100;
      }
    }
  }
`

export const style = {}

export default style
