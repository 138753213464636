import styled from 'styled-components'
import play from './play.png'

export const Container = styled.div`
  height: 60px;
  width: 100%;
  box-shadow: border-box;
  position: absolute;
  bottom: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  pointer-events: none;

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .buttons {
      & > * {
        pointer-events: all;
        cursor: pointer;
      }
      display: flex;
      width: 200px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      .arrow {
        color: #333;
        padding: 5px 10px;
        /* opacity: 0.4; */
        &:hover {
          transform: scale(1.1);
          opacity: 1;
          font-weight: bold;
        }
      }
    }
    /* .but-rotate {
      height: 60px;
      width: 60px;
    } */
    .but-rotate:hover {
      transform: scale(1.1);
      opacity: 1;
    }
    .but-rotate {
      height: 50px;
      width: 50px;
      border: none;
      border-radius: 50%;
      background: transparent url(${play}) no-repeat center;
      -webkit-background-size: 84% auto;
      background-size: 84% auto;
      box-shadow: 0 0 0 rgba(255, 51, 0, 0.4);
      /* animation: pulse-shadow-blue 2s infinite; */
      transition: all 0.3s;
      /* opacity: 0.4; */
    }
    .but-rotate.rotating {
      opacity: 0.9;
      transform: scale(1.1);
      /* animation: 0s ease 0s 1 normal none running none; */
    }
  }
  @keyframes pulse-shadow-blue {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.6);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  @media (max-width: 768px) {
    bottom: 40%;
    transform: translateY(-50%);
    .but-rotate {
      display: none;
    }
    .navigation {
      width: 100%;
      padding: 0 10px;
      .buttons {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
`

export const style = {}

export default style
