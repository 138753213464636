import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { style, Container } from './Configurator.style'
import textures from '../Model/textures'
import { ReactComponent as Pointer } from './pointer.svg'

class Configurator extends Component {
  render() {
    const {
      changeYarn,
      changeColor,
      changeGlobalColor,
      changeGroupTextures,
      changeMultipleColors,
      currentMaterials,
      selectedTarget,
      selectTarget,
      model,
    } = this.props
    const { yarn: activeYarn } = currentMaterials
    const bodyParts = Object.keys(textures[model.id].defaultState.parts)
    const yarns = Object.keys(textures[model.id].yarns)

    return (
      <Container className={model.id}>
        {activeYarn && (
          <div className="left">
            <div className="moreInfo">
              <a
                href="https://22factor.com/pages/on-demand-3d-knitting"
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                More information
              </a>
            </div>
            {yarns.map((yarn, i) => (
              <React.Fragment>
                <div
                  className={`yarnType button ${activeYarn === yarn ? ' active' : ' '}`}
                  role="presentation"
                  onClick={() => changeYarn(yarn)}
                >
                  {(textures[model.id] && textures[model.id].yarnAliases[yarn]) ||
                    yarn.charAt(0).toUpperCase() +
                      yarn.slice(1).replace(/_\w/g, (m) => {
                        return ' ' + m[1].toUpperCase()
                      })}
                </div>
                {i < yarns.length - 1 && <div className={'divider'} />}
              </React.Fragment>
            ))}
          </div>
        )}
        {activeYarn && (
          <div className="right">
            {/* <div className="bodyPart button">
              {selectedTarget.charAt(0).toUpperCase() +
                selectedTarget.slice(1).replace(/_\w/g, (m) => {
                  return ' ' + m[1].toUpperCase()
                })}
            </div> */}
            {model.id === 'lynx' && (
              <div className="bodyPartsWrapper">
                <div className="bodyPartsBlock">
                  <div className="button bodyPart active heading">Choose Stitch</div>
                  {[
                    { name: 'Front and Back Panels', targets: ['part1_mat', 'part2_mat1', 'part2_mat2'] },
                    { name: 'Sleeves and Partial Front Right Panel', targets: ['part3_mat1', 'part3_mat2'] },
                  ].map((part) => (
                    <div
                      className={
                        'bodyPart button' +
                        ((selectedTarget || '').toString() === part.targets.toString() ? ' active' : '')
                      }
                      onClick={() => selectTarget(part.targets)}
                    >
                      {part.name}
                    </div>
                  ))}
                </div>
                <div className={'divider' + (model.id === 'lynx' ? ' minimal' : '')} />
                <div className="texturesBlockWrapper">
                  <div className={'texturesBlock'}>
                    {selectedTarget &&
                      [...Array(textures[model.id].textureButtons.length)].map((_, i) => (
                        <div
                          className={
                            'textureButton' +
                            ((currentMaterials.parts[(selectedTarget || [])[0]] || {}).noding ===
                            textures[model.id].textureButtons[i].name
                              ? ' active'
                              : '')
                          }
                          role="presentation"
                          onClick={() =>
                            changeGroupTextures({
                              targets: selectedTarget,
                              noding: textures[model.id].textureButtons[i].name,
                            })
                          }
                        >
                          <div
                            className="texture"
                            style={{
                              backgroundImage: `url(${textures[model.id].textureButtons[i].image})`,
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className="bodyPartsWrapper">
              <div className="bodyPartsBlock">
                {model.id !== 'lynx' &&
                  bodyParts
                    .filter((part) => !(part === 'neck' && model.id === 'indus'))
                    .map((part) => (
                      <div
                        className={'bodyPart button' + (selectedTarget === part ? ' active' : '')}
                        onClick={() => selectTarget(part)}
                      >
                        {textures[model.id].defaultState.parts[part].alias ||
                          part.charAt(0).toUpperCase() +
                            part.slice(1).replace(/_\w/g, (m) => {
                              return ' ' + m[1].toUpperCase()
                            })}
                      </div>
                    ))}
                {model.id === 'lynx' && <div className="button bodyPart active">Choose Color</div>}
              </div>
            </div>
            <div className={'divider' + (model.id === 'lynx' ? ' minimal' : '')} />
            <div className="colorsBlockWrapper">
              {model.id !== 'lynx' && selectedTarget && textures[model.id].yarns[activeYarn] && (
                <div className={'colorsBlock' + (!!(textures[model.id].defaultColors.count % 4) ? ' suffixed' : '')}>
                  {[...Array(textures[model.id].defaultColors.count)].map((_, i) => (
                    <div
                      className={
                        'colorButton' +
                        (currentMaterials.parts[selectedTarget].color === ('0' + (i + 1)).slice(-2) ? ' active' : '')
                      }
                      role="presentation"
                      onClick={() => {
                        if (selectedTarget === 'body' && model.id === 'indus') {
                          return changeMultipleColors(['neck', 'body'], ('0' + (i + 1)).slice(-2))
                        }
                        changeColor({
                          ...currentMaterials.parts[selectedTarget],
                          noding: currentMaterials.parts[selectedTarget].noding,
                          part: selectedTarget,
                          color: ('0' + (i + 1)).slice(-2),
                        })
                      }}
                    >
                      <div
                        className="texture"
                        style={{
                          backgroundImage: `url(${
                            textures[model.id].yarns[activeYarn][currentMaterials.parts[selectedTarget].noding][
                              ('0' + (i + 1)).slice(-2)
                            ]
                          })`,
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
              {model.id === 'lynx' && (
                <div className={'colorsBlock' + (!!(textures[model.id].defaultColors.count % 4) ? ' suffixed' : '')}>
                  {[...Array(textures[model.id].defaultColors.count)].map((_, i) => (
                    <div
                      className={
                        'colorButton' +
                        (currentMaterials.parts.part1_mat.color === ('0' + (i + 1)).slice(-2) ? ' active' : '')
                      }
                      role="presentation"
                      onClick={() => changeGlobalColor(('0' + (i + 1)).slice(-2))}
                    >
                      <div
                        className="texture"
                        style={{
                          backgroundImage: `url(${
                            textures[model.id].yarns[activeYarn][currentMaterials.parts.part1_mat.noding][
                              ('0' + (i + 1)).slice(-2)
                            ]
                          })`,
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    )
  }
}

Configurator.propTypes = {}

export default Configurator
