import React from 'react'

import { style, Container } from './FooterAside.style'

const FooterAside = () => {
  return (
    <Container>
      <div className="copyright">
        <a href="https://22factor.com" className="link" target="_blank" rel="noopener noreferrer">
          © 22factor
        </a>
      </div>
      <div className="moreInfo">
        <a
          href="https://22factor.com/pages/on-demand-3d-knitting"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          More information
        </a>
      </div>
    </Container>
  )
}

export default FooterAside
