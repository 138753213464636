const urlPrefix = './lynx_'
export default {
  namesByPart: (part) => {
    switch (part) {
      case 'part1_mat':
      case 'part2_mat1':
      case 'part2_mat2':
        return ['part1_mat', 'part2_mat1', 'part2_mat2']
      case 'part3_mat1':
      case 'part3_mat2':
        return ['part3_mat1', 'part3_mat2']
      default:
        return null
    }
  },
  defaultColors: {
    count: 7,
    cashmere: '04',
    wool: '04',
  },
  yarnAliases: {
    cashmere: 'Cashmere',
    wool: 'Merino Wool Nylon',
  },
  textureButtons: [
    {
      name: '1x1',
      image: require(`./1x1.png`),
    },
    {
      name: 'cable1',
      image: require(`./cable1.png`),
    },
    {
      name: 'cable2',
      image: require(`./cable2.png`),
    },
    {
      name: 'links',
      image: require(`./links.png`),
    },
  ],
  defaultState: {
    yarn: 'cashmere',
    parts: {
      part1_mat: {
        noding: 'cable2',
        color: '04',
        alias: 'Front and Back Panels',
      },
      part2_mat1: {
        noding: 'cable2',
        color: '04',
        alias: 'Front and Back Panels',
      },
      part2_mat2: {
        noding: 'cable2',
        color: '04',
        alias: 'Front and Back Panels',
      },
      part3_mat1: {
        noding: '1x1',
        color: '04',
        alias: 'Sleeves and Partial Front Right Panel',
      },
      part3_mat2: {
        noding: '1x1',
        color: '04',
        alias: 'Sleeves and Partial Front Right Panel',
      },
      part3_mat3: {
        ignore: true,
        noding: '2x2v',
        color: '04',
        alias: 'Sleeves and Partial Front Right Panel',
      },
      part4_mat1: {
        ignore: true,
        noding: '2x2v',
        color: '04',
      },
      part4_mat2: {
        ignore: true,
        noding: '2x2h',
        color: '04',
      },
    },
  },
  yarns: {
    cashmere: {
      '1x1': {
        normal: require(`${urlPrefix}1x1_normal.jpg`),
        op: require(`${urlPrefix}1x1_op.jpg`),
        '01': require(`${urlPrefix}cashmere_1x1_01.jpg`),
        '02': require(`${urlPrefix}cashmere_1x1_02.jpg`),
        '03': require(`${urlPrefix}cashmere_1x1_03.jpg`),
        '04': require(`${urlPrefix}cashmere_1x1_04.jpg`),
        '05': require(`${urlPrefix}cashmere_1x1_05.jpg`),
        '06': require(`${urlPrefix}cashmere_1x1_06.jpg`),
        '07': require(`${urlPrefix}cashmere_1x1_07.jpg`),
      },
      '2x2h': {
        '01': require(`${urlPrefix}cashmere_2x2h_01.jpg`),
        '02': require(`${urlPrefix}cashmere_2x2h_02.jpg`),
        '03': require(`${urlPrefix}cashmere_2x2h_03.jpg`),
        '04': require(`${urlPrefix}cashmere_2x2h_04.jpg`),
        '05': require(`${urlPrefix}cashmere_2x2h_05.jpg`),
        '06': require(`${urlPrefix}cashmere_2x2h_06.jpg`),
        '07': require(`${urlPrefix}cashmere_2x2h_07.jpg`),
      },
      '2x2v': {
        '01': require(`${urlPrefix}cashmere_2x2v_01.jpg`),
        '02': require(`${urlPrefix}cashmere_2x2v_02.jpg`),
        '03': require(`${urlPrefix}cashmere_2x2v_03.jpg`),
        '04': require(`${urlPrefix}cashmere_2x2v_04.jpg`),
        '05': require(`${urlPrefix}cashmere_2x2v_05.jpg`),
        '06': require(`${urlPrefix}cashmere_2x2v_06.jpg`),
        '07': require(`${urlPrefix}cashmere_2x2v_07.jpg`),
      },
      cable1: {
        normal: require(`${urlPrefix}cable1_normal.jpg`),
        op: require(`${urlPrefix}cable1_op.jpg`),
        '01': require(`${urlPrefix}cashmere_cable1_01.jpg`),
        '02': require(`${urlPrefix}cashmere_cable1_02.jpg`),
        '03': require(`${urlPrefix}cashmere_cable1_03.jpg`),
        '04': require(`${urlPrefix}cashmere_cable1_04.jpg`),
        '05': require(`${urlPrefix}cashmere_cable1_05.jpg`),
        '06': require(`${urlPrefix}cashmere_cable1_06.jpg`),
        '07': require(`${urlPrefix}cashmere_cable1_07.jpg`),
      },
      cable2: {
        normal: require(`${urlPrefix}cable2_normal.jpg`),
        op: require(`${urlPrefix}cable2_op.jpg`),
        '01': require(`${urlPrefix}cashmere_cable2_01.jpg`),
        '02': require(`${urlPrefix}cashmere_cable2_02.jpg`),
        '03': require(`${urlPrefix}cashmere_cable2_03.jpg`),
        '04': require(`${urlPrefix}cashmere_cable2_04.jpg`),
        '05': require(`${urlPrefix}cashmere_cable2_05.jpg`),
        '06': require(`${urlPrefix}cashmere_cable2_06.jpg`),
        '07': require(`${urlPrefix}cashmere_cable2_07.jpg`),
      },
      links: {
        normal: require(`${urlPrefix}links_normal.jpg`),
        op: require(`${urlPrefix}links_op.jpg`),
        '01': require(`${urlPrefix}cashmere_links_01.jpg`),
        '02': require(`${urlPrefix}cashmere_links_02.jpg`),
        '03': require(`${urlPrefix}cashmere_links_03.jpg`),
        '04': require(`${urlPrefix}cashmere_links_04.jpg`),
        '05': require(`${urlPrefix}cashmere_links_05.jpg`),
        '06': require(`${urlPrefix}cashmere_links_06.jpg`),
        '07': require(`${urlPrefix}cashmere_links_07.jpg`),
      },
    },
    wool: {
      '1x1': {
        normal: require(`${urlPrefix}1x1_normal.jpg`),
        op: require(`${urlPrefix}1x1_op.jpg`),
        '01': require(`${urlPrefix}wool_1x1_01.jpg`),
        '02': require(`${urlPrefix}wool_1x1_02.jpg`),
        '03': require(`${urlPrefix}wool_1x1_03.jpg`),
        '04': require(`${urlPrefix}wool_1x1_04.jpg`),
        '05': require(`${urlPrefix}wool_1x1_05.jpg`),
        '06': require(`${urlPrefix}wool_1x1_06.jpg`),
        '07': require(`${urlPrefix}wool_1x1_07.jpg`),
      },
      '2x2h': {
        '01': require(`${urlPrefix}wool_2x2h_01.jpg`),
        '02': require(`${urlPrefix}wool_2x2h_02.jpg`),
        '03': require(`${urlPrefix}wool_2x2h_03.jpg`),
        '04': require(`${urlPrefix}wool_2x2h_04.jpg`),
        '05': require(`${urlPrefix}wool_2x2h_05.jpg`),
        '06': require(`${urlPrefix}wool_2x2h_06.jpg`),
        '07': require(`${urlPrefix}wool_2x2h_07.jpg`),
      },
      '2x2v': {
        '01': require(`${urlPrefix}wool_2x2v_01.jpg`),
        '02': require(`${urlPrefix}wool_2x2v_02.jpg`),
        '03': require(`${urlPrefix}wool_2x2v_03.jpg`),
        '04': require(`${urlPrefix}wool_2x2v_04.jpg`),
        '05': require(`${urlPrefix}wool_2x2v_05.jpg`),
        '06': require(`${urlPrefix}wool_2x2v_06.jpg`),
        '07': require(`${urlPrefix}wool_2x2v_07.jpg`),
      },
      cable1: {
        normal: require(`${urlPrefix}cable1_normal.jpg`),
        op: require(`${urlPrefix}cable1_op.jpg`),
        '01': require(`${urlPrefix}wool_cable1_01.jpg`),
        '02': require(`${urlPrefix}wool_cable1_02.jpg`),
        '03': require(`${urlPrefix}wool_cable1_03.jpg`),
        '04': require(`${urlPrefix}wool_cable1_04.jpg`),
        '05': require(`${urlPrefix}wool_cable1_05.jpg`),
        '06': require(`${urlPrefix}wool_cable1_06.jpg`),
        '07': require(`${urlPrefix}wool_cable1_07.jpg`),
      },
      cable2: {
        normal: require(`${urlPrefix}cable2_normal.jpg`),
        op: require(`${urlPrefix}cable2_op.jpg`),
        '01': require(`${urlPrefix}wool_cable2_01.jpg`),
        '02': require(`${urlPrefix}wool_cable2_02.jpg`),
        '03': require(`${urlPrefix}wool_cable2_03.jpg`),
        '04': require(`${urlPrefix}wool_cable2_04.jpg`),
        '05': require(`${urlPrefix}wool_cable2_05.jpg`),
        '06': require(`${urlPrefix}wool_cable2_06.jpg`),
        '07': require(`${urlPrefix}wool_cable2_07.jpg`),
      },
      links: {
        normal: require(`${urlPrefix}links_normal.jpg`),
        op: require(`${urlPrefix}links_op.jpg`),
        '01': require(`${urlPrefix}wool_links_01.jpg`),
        '02': require(`${urlPrefix}wool_links_02.jpg`),
        '03': require(`${urlPrefix}wool_links_03.jpg`),
        '04': require(`${urlPrefix}wool_links_04.jpg`),
        '05': require(`${urlPrefix}wool_links_05.jpg`),
        '06': require(`${urlPrefix}wool_links_06.jpg`),
        '07': require(`${urlPrefix}wool_links_07.jpg`),
      },
    },
  },
}
