const urlPrefix = './indus_'
export default {
  namesByPart: (part) => {
    switch (part) {
      case 'neck':
      case 'body':
        return ['body', 'neck']
      case 'stripe1':
        return ['stripe1']
      case 'stripe2':
        return ['stripe2']
      case 'stripe3':
        return ['stripe3']
      case 'stripe4':
        return ['stripe4']
      default:
        return null
    }
  },
  defaultColors: {
    count: 8,
    cashmere: '05',
    cotton: '04',
    cashmereByPart: {
      neck: '05',
      body: '05',
      stripe1: '03',
      stripe2: '04',
      stripe3: '02',
      stripe4: '07',
    },
    cottonByPart: {
      neck: '04',
      body: '04',
      stripe1: '02',
      stripe2: '01',
      stripe3: '06',
      stripe4: '08',
    },
  },
  yarnAliases: {
    cashmere: 'Cashmere',
    cotton: 'Premium Cotton',
  },
  defaultState: {
    yarn: 'cashmere',
    parts: {
      neck: {
        alias: 'Edging',
        noding: '2x2',
        color: '05',
      },
      body: {
        noding: '1x1',
        color: '05',
      },
      stripe1: {
        alias: 'Stripe #1',
        noding: '1x1',
        color: '03',
      },
      stripe2: {
        alias: 'Stripe #2',
        noding: '1x1',
        color: '04',
      },
      stripe3: {
        alias: 'Stripe #3',
        noding: '1x1',
        color: '02',
      },
      stripe4: {
        alias: 'Stripe #4',
        noding: '1x1',
        color: '07',
      },
    },
  },
  yarns: {
    cashmere: {
      '1x1': {
        cavity: require(`${urlPrefix}cashmere_1x1_cavity.jpg`),
        normal: require(`${urlPrefix}cashmere_1x1_normal.jpg`),
        '01': require(`${urlPrefix}cashmere_1x1_01.jpg`),
        '02': require(`${urlPrefix}cashmere_1x1_02.jpg`),
        '03': require(`${urlPrefix}cashmere_1x1_03.jpg`),
        '04': require(`${urlPrefix}cashmere_1x1_04.jpg`),
        '05': require(`${urlPrefix}cashmere_1x1_05.jpg`),
        '06': require(`${urlPrefix}cashmere_1x1_06.jpg`),
        '07': require(`${urlPrefix}cashmere_1x1_07.jpg`),
        '08': require(`${urlPrefix}cashmere_1x1_08.jpg`),
      },
      '2x2': {
        cavity: require(`${urlPrefix}cashmere_2x2_cavity.jpg`),
        normal: require(`${urlPrefix}cashmere_2x2_normal.jpg`),
        '01': require(`${urlPrefix}cashmere_2x2_01.jpg`),
        '02': require(`${urlPrefix}cashmere_2x2_02.jpg`),
        '03': require(`${urlPrefix}cashmere_2x2_03.jpg`),
        '04': require(`${urlPrefix}cashmere_2x2_04.jpg`),
        '05': require(`${urlPrefix}cashmere_2x2_05.jpg`),
        '06': require(`${urlPrefix}cashmere_2x2_06.jpg`),
        '07': require(`${urlPrefix}cashmere_2x2_07.jpg`),
        '08': require(`${urlPrefix}cashmere_2x2_08.jpg`),
      },
    },
    cotton: {
      '1x1': {
        cavity: require(`${urlPrefix}cotton_1x1_cavity.jpg`),
        normal: require(`${urlPrefix}cotton_1x1_normal.jpg`),
        '01': require(`${urlPrefix}cotton_1x1_01.jpg`),
        '02': require(`${urlPrefix}cotton_1x1_02.jpg`),
        '03': require(`${urlPrefix}cotton_1x1_03.jpg`),
        '04': require(`${urlPrefix}cotton_1x1_04.jpg`),
        '05': require(`${urlPrefix}cotton_1x1_05.jpg`),
        '06': require(`${urlPrefix}cotton_1x1_06.jpg`),
        '07': require(`${urlPrefix}cotton_1x1_07.jpg`),
        '08': require(`${urlPrefix}cotton_1x1_08.jpg`),
      },
      '2x2': {
        cavity: require(`${urlPrefix}cotton_2x2_cavity.jpg`),
        normal: require(`${urlPrefix}cotton_2x2_normal.jpg`),
        '01': require(`${urlPrefix}cotton_2x2_01.jpg`),
        '02': require(`${urlPrefix}cotton_2x2_02.jpg`),
        '03': require(`${urlPrefix}cotton_2x2_03.jpg`),
        '04': require(`${urlPrefix}cotton_2x2_04.jpg`),
        '05': require(`${urlPrefix}cotton_2x2_05.jpg`),
        '06': require(`${urlPrefix}cotton_2x2_06.jpg`),
        '07': require(`${urlPrefix}cotton_2x2_07.jpg`),
        '08': require(`${urlPrefix}cotton_2x2_08.jpg`),
      },
    },
  },
}
