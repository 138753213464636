import React, { Component } from 'react'
import domtoimage from 'dom-to-image'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { sendEmail } from '../../../../services/email-service'
import { Container } from './SubmissionModal.style'
import textures from '../../../../components/Model/textures'

// const bodyParts = ['neck', 'body_upper', 'body_lower', 'left_sleeve', 'right_sleeve']

class SubmissionModal extends Component {
  constructor(props) {
    super(props)
    const { initialData } = props
    const { name, email, company, country, quantity, salesChannel, comments } = initialData || {}
    this.state = {
      image: null,
      showColors: true,
      name: name || '',
      email: email || '',
      company: company || '',
      country: country || '',
      quantity: quantity || '',
      salesChannel: salesChannel || '',
      comments: comments || '',
    }
  }

  bodyParts = textures[this.props.model.id].namesByPart
    ? Object.keys(textures[this.props.model.id].defaultState.parts).filter((part) => {
        const array = textures[this.props.model.id].namesByPart(part)
        if (array && part === array[0]) {
          return true
        }
        return false
      })
    : Object.keys(textures[this.props.model.id].defaultState.parts)

  componentDidMount() {
    const { api } = this.props

    const status = 'success'
    this.setState({
      emailIsSending: false,
      status,
      message:
        status === 'success'
          ? 'Your order is successfully submitted! Check your mail for order details'
          : 'Something went wrong. Please contact us directly to hello@22factor.com',
    })

    if (!!api) {
      api.gotoAnnotation(0)
      setTimeout(
        () =>
          api.getScreenShot(800, 1000, 'image/jpg', (err, image) => {
            this.setState({
              image,
            })
          }),
        1000
      )
    }

    domtoimage
      .toJpeg(this.panel, { quality: 0.95, bgcolor: '#fff' })
      .then((partsImage) => {
        this.setState({
          partsImage,
        })
      })
      .catch(function(error) {
        console.error('oops, something went wrong!', error)
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { image } = this.state
    const { image: prevImage } = prevState

    if (!!image && !prevImage) {
      this.setState({ showColors: false })
    }
  }

  submitForm = () => {
    const { materials, model } = this.props
    const { name, email, company, country, quantity, salesChannel, comments, partsImage, image } = this.state
    const yarnName =
      (textures[model.id].yarnAliases && textures[model.id].yarnAliases[materials.yarn]) ||
      materials.yarn.charAt(0).toUpperCase() + materials.yarn.slice(1)
    const styleName = model.id.charAt(0).toUpperCase() + model.id.slice(1)

    const colorDetails = this.bodyParts
      .map(
        (part) =>
          `${textures[model.id].defaultState.parts[part].alias ||
            part.charAt(0).toUpperCase() +
              part.slice(1).replace(/_\w/g, (m) => {
                return ' ' + m[1].toUpperCase()
              })} - [${model.id === 'lynx' ? materials.parts[part].noding + ', ' : ''}${materials.parts[part].color}]`
      )
      .join('; ')

    this.setState({ emailIsSending: true })
    sendEmail(
      {
        name,
        email,
        company,
        country,
        quantity,
        salesChannel,
        comments,
        partsImage,
        image,
        yarnName,
        styleName,
        colorDetails,
      },
      (msg, status) => {
        console.log(msg)
        this.setState({
          emailIsSending: false,
          status,
          message:
            status === 'success'
              ? 'Your order is successfully submitted! Check your mail for order details'
              : 'Something went wrong. Please contact us directly to hello@22factor.com',
        })
        if (status === 'success') {
          toast.success('Your order is successfully submitted! Check your mail for order details', {
            position: 'top-center',
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
          })
        } else {
          toast.error('Something went wrong. Please contact us directly to hello@22factor.com', {
            position: 'top-center',
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
          })
        }
      }
    )
  }

  render() {
    const { materials, model } = this.props
    const {
      image,
      showColors,
      name,
      email,
      company,
      country,
      quantity,
      salesChannel,
      comments,
      emailIsSending,
      // message,
      // status,
    } = this.state
    const yarnName =
      (textures[model.id].yarnAliases && textures[model.id].yarnAliases[materials.yarn]) ||
      materials.yarn.charAt(0).toUpperCase() + materials.yarn.slice(1)
    return (
      <Container>
        <div className="popup">
          <div className="popup_inner">
            <div className="title">Your Configuration</div>

            <div className="content">
              <div className="formWrapper">
                <h1 class="SectionHeader__Heading Heading u-h1">Fill the form:</h1>
                <form
                  // method="post"
                  // action="/contact#contact_form"
                  id="contact_form"
                  accept-charset="UTF-8"
                  className="Form Form--spacingTight"
                  ref={(ref) => (this.form = ref)}
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.submitForm()
                  }}
                >
                  <div className="Form__Item">
                    <input
                      type="text"
                      className="Form__Input"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        const { value: name } = e.target
                        this.setState({ name })
                      }}
                      aria-label="Name"
                      placeholder="Name"
                      required
                      tabindex="1"
                    />
                    <label className="Form__FloatingLabel">Name</label>
                  </div>

                  <div className="Form__Item">
                    <input
                      type="email"
                      className="Form__Input"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        const { value: email } = e.target
                        this.setState({ email })
                      }}
                      aria-label="Email"
                      placeholder="Email"
                      required
                      tabindex="2"
                    />
                    <label className="Form__FloatingLabel">Email</label>
                  </div>

                  <div className="Form__Item">
                    <input
                      type="text"
                      className="Form__Input"
                      name="company"
                      value={company}
                      onChange={(e) => {
                        const { value: company } = e.target
                        this.setState({ company })
                      }}
                      aria-label="Company/organisation"
                      placeholder="Company/organisation"
                      required
                      tabindex="3"
                    />
                    <label className="Form__FloatingLabel">Company/organisation</label>
                  </div>

                  <div className="Form__Item">
                    <input
                      type="text"
                      className="Form__Input"
                      name="country"
                      value={country}
                      onChange={(e) => {
                        const { value: country } = e.target
                        this.setState({ country })
                      }}
                      aria-label="Country"
                      placeholder="Country"
                      required
                      tabindex="4"
                    />
                    <label className="Form__FloatingLabel">Country</label>
                  </div>

                  <div className="Form__Item">
                    <input
                      type="number"
                      min={50}
                      className="Form__Input"
                      name="quantity"
                      value={quantity}
                      onChange={(e) => {
                        const { value: quantity } = e.target
                        this.setState({ quantity })
                      }}
                      aria-label="Estimated Quantity"
                      placeholder="Estimated Quantity (min 50)"
                      required
                      tabindex="5"
                    />
                    <label className="Form__FloatingLabel">Estimated Quantity</label>
                  </div>

                  <div className="Form__Item">
                    <input
                      type="text"
                      className="Form__Input"
                      name="salesChannel"
                      value={salesChannel}
                      onChange={(e) => {
                        const { value: salesChannel } = e.target
                        this.setState({ salesChannel })
                      }}
                      aria-label="Sales Channel"
                      placeholder="Sales Channel"
                      required
                      tabindex="6"
                    />
                    <label className="Form__FloatingLabel">Sales Channel</label>
                  </div>

                  <div className="Form__Item">
                    <textarea
                      name="comments"
                      value={comments}
                      onChange={(e) => {
                        const { value: comments } = e.target
                        this.setState({ comments })
                      }}
                      cols="30"
                      rows="3"
                      className="Form__Textarea"
                      aria-label="Anything else we should know?"
                      placeholder="Anything else we should know? (optional)"
                      tabindex="7"
                    />
                    <label className="Form__FloatingLabel">Anything else we should know?</label>
                  </div>
                  <input
                    type="submit"
                    ref={(ref) => {
                      this.submit = ref
                    }}
                    style={{ height: 0, width: 0, opacity: 0, pointerEvents: 'none' }}
                  />
                </form>
              </div>

              <div className="designWrapper">
                <div
                  className="design"
                  ref={(ref) => {
                    this.design = ref
                  }}
                >
                  <div className="summary">
                    <div className="summaryItem">Style: {model.id.charAt(0).toUpperCase() + model.id.slice(1)}</div>
                    <div className="summaryItem">Yarn: {yarnName}</div>
                    <button
                      className={'accordion' + (showColors ? ' active' : '')}
                      onClick={() => this.setState({ showColors: !showColors })}
                    >
                      Part Details
                      {showColors ? <i className="arrow up" /> : <i className="arrow down" />}
                    </button>
                  </div>
                  <div className="divider" />
                  <div className="imageWrappert">
                    <div
                      ref={(ref) => {
                        this.panel = ref
                      }}
                      class="panel"
                      style={{ maxHeight: showColors ? '600' + 'px' : 0 }}
                      onClick={() => this.setState({ showColors: false })}
                    >
                      {this.bodyParts.map((part) => (
                        <div className="row" key={part}>
                          <div className="col">
                            {textures[model.id].defaultState.parts[part].alias ||
                              part.charAt(0).toUpperCase() +
                                part.slice(1).replace(/_\w/g, (m) => {
                                  return ' ' + m[1].toUpperCase()
                                })}
                          </div>
                          <div className="col">
                            {model.id === 'lynx' && <span className="stitching">{materials.parts[part].noding}</span>}
                            <div
                              className="texture"
                              style={{
                                backgroundImage: `url(${
                                  textures[model.id].yarns[materials.yarn][materials.parts[part].noding][
                                    materials.parts[part].color
                                  ]
                                })`,
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="productImage" style={{ backgroundImage: `url(${image})` }}>
                      {!image && <div className="note">Rendering...</div>}
                      {/* {message && <div className={`message ${status}`}>{message}</div>} */}
                    </div>
                  </div>
                  {/* <img src={image} alt="" /> */}
                </div>
              </div>
            </div>
            <button
              disabled={emailIsSending}
              type="submit"
              onClick={() => this.submit.click()}
              className="Form__Submit Button Button--primary"
            >
              {emailIsSending ? 'Sending...' : 'Submit Your Enquiry'}
            </button>

            <div
              className="close"
              onClick={() => this.props.onClose({ name, email, company, country, quantity, salesChannel, comments })}
            >
              ✕
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </Container>
    )
  }
}

SubmissionModal.propTypes = {}

export default SubmissionModal
