import styled from 'styled-components'
import mainLogo from './logo.png'

const Container = styled.div`
  font-weight: 600;
  position: relative;
  /* letter-spacing: 1px; */
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  &,
  .main {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .header {
    position: relative;
    &,
    * {
      z-index: 99;
    }
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(14px + 2vmin);
    color: #000;
    height: 40px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.7);
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 40px;
      bottom: -40px;
      background-color: rgba(255, 255, 255, 0.7);
    }
    @media (max-width: 768px) {
      height: 60px;
      &:after {
        height: 15px;
        bottom: -15px;
      }
    }
  }
  .logo {
    display: block;
    width: 110px;
    height: 30px;
    background-image: url(${mainLogo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    text-indent: -99999px;
    padding: 15px 0;
    transition: all 0.2s ease-in-out;
    z-index: 100;
    @media (max-width: 768px) {
      width: 70px;
    }
  }

  .button {
    user-select: none;
    cursor: pointer;
    color: #939393;
    transition: all 0.2s ease-in-out;
    font-size: 18px;
    font-weight: 100;
    /* background-color: rgba(255, 255, 255, 0.42); */
    &:hover {
      color: #333;
    }
    &.active {
      color: #333;
    }
  }

  .link {
    user-select: none;
    cursor: pointer;
    color: #333;
    transition: all 0.2s ease-in-out;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      color: #939393;
    }
  }

  .hintWrapper {
    pointer-events: none;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 50%);
    transition: opacity 0.2s ease-in-out;
    text-align: center;

    &:before {
      content: '';
      height: 12px;
      width: 12px;
      background: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      animation: pulse-shadow 2s infinite;
      margin: -46px 0px 0 -14px;
    }
    .hint {
      * {
        transition: all 0.2s ease-in-out;
      }
      .button {
        white-space: nowrap;
        color: #333;
      }
    }
  }

  @media (max-width: 768px) {
    .button {
      font-size: 15px;
    }
    .hintWrapper {
      &:before {
        margin: -41px 0px 0 -14px;
      }
    }
  }
`

export { Container }
