import styled from 'styled-components'

export const Container = styled.div`
  .iframe {
    border: none;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.1s;
    position: absolute;
    top: 100px;
    height: calc(100% - 152px);
  }

  @media (max-width: 768px) {
    .iframe {
      top: 50px;
      height: calc(100% - 152px);
    }
  }
`

export const style = {}

export default style
