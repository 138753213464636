import styled from 'styled-components'
import './common-form-input-style.css'

export const Container = styled.div`
  position: absolute;
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    cursor: default;
  }
  .popup_inner {
    position: absolute;
    left: 15%;
    right: 15%;
    top: 7%;
    bottom: 7%;
    margin: auto;
    background: white;
    z-index: 101;
    font-size: 18px;
    font-weight: 100;
    color: #333;
    padding: 30px;
    padding-bottom: 80px;
  }
  .content {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 5px;
    margin: -5px;
    display: flex;
    flex-direction: row;
    & > div {
      width: 100%;
    }
    /* padding-bottom: 30px; */
  }
  .formWrapper {
    z-index: 1000;
  }
  .designWrapper {
    position: relative;
  }
  .summary {
    margin: 16px 0;
    .summaryItem {
      font-size: 20px;
      text-align: center;
    }
  }
  .stitching {
    line-height: 30px;
    font-size: 14px;
    padding-right: 10px;
  }
  .texture {
    background-size: 350%;
    background-position: center;
    height: 30px;
    width: 30px;
    /* border-radius: 100%; */
  }
  .divider {
    height: 1px;
    width: 80%;
    background-color: #333;
    margin: 20px auto;
  }
  .title {
    position: absolute;
    top: -32px;
    left: 0px;
    font-size: 22px;
    color: #fff;
  }
  .close {
    position: absolute;
    cursor: pointer;
    top: -35px;
    right: 0px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 10px 0;
    .col {
      display: flex;
      flex-direction: col;
      justify-content: space-around;
      align-content: center;
    }
  }

  .Form__Submit {
    bottom: calc(5% + 30px);
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 300px;
    z-index: 1000;
  }
  .message {
    bottom: calc(5% + 15px);
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    /* width: 90%; */
    /* max-width: 300px; */
    z-index: 1000;
    font-size: 12px;
    text-align: center;
    &.error {
      color: #ff5525;
    }
    &.success {
      color: #94c34c;
    }
  }

  .accordion {
    /* background-color: #eee; */
    background: transparent;
    /* color: #444; */
    cursor: pointer;
    /* padding: 18px; */
    margin: auto;
    width: 100%;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 20px;
    text-align: center;
    font-family: 'Futura PT Book', 'Avenir LT 55 Roman', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  i {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transition: transform 0.2s ease-out;
  }

  .up {
    transform: rotate(-135deg) translate(-4px, 4px);
  }

  .down {
    transform: rotate(45deg) translate(2px, -6px);
  }
  .imageWrappert {
    position: relative;
  }
  .panel {
    background-color: rgba(255, 255, 255, 0.8);
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    position: absolute;
    width: 80%;
    padding: 0 10%;
    top: 0;
    .row .col:first-child {
      margin-right: 30px;
    }
  }
  .productImage {
    width: 100%;
    height: 400px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .popup_inner {
      /* width: 90%; */
      /* margin: auto; */
      left: 5%;
      right: 5%;
      padding-bottom: 45px;
      padding-top: 5px;
    }
    .content {
      flex-direction: column;
    }
    .productImage {
      height: 350px;
    }
    .Form__Submit {
      bottom: 7%;
      max-width: 90%;
    }
    .message {
      bottom: calc(7% + 45px);
      width: 100%;
    }
    .panel {
      width: 94%;
      padding: 0 3%;
    }
  }
`

export const style = {}

export default style
