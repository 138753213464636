import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Logo } from './logo_white.svg'

import { style, Container } from './LogoLoading.style'

class LogoLoading extends Component {
  render() {
    const { show } = this.props
    return (
      <Container style={show ? {} : { opacity: 0, zIndex: -1 }}>
        <Logo />
      </Container>
    )
  }
}

LogoLoading.propTypes = {
  show: PropTypes.bool,
}

export default LogoLoading
