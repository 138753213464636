import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SketchfabWrapper from 'sketchfab-api-wrapper'
import iframeOptions from './iframeOptions.json'
import { style, Container } from './Model.style'

import textures from './textures'

// const bodyParts = ['neck', 'body_upper', 'body_lower', 'left_sleeve', 'right_sleeve']

class Model extends Component {
  state = {
    iframeHeight: window.innerHeight - (window.innerWidth > 768 ? 100 : 225),
    iframeWidth: Math.min(window.innerWidth, 1200),
  }

  bodyParts = Object.keys(textures[this.props.model.id].defaultState.parts)

  componentDidMount() {
    const { uid, onApiLoaded, onMaterialSelect, model, materials } = this.props
    const { yarns } = textures[model.id]

    // Preload all textures for the mode
    const textureUrls = []
    for (let yarn in yarns) {
      for (let node in yarns[yarn]) {
        for (let color in yarns[yarn][node]) {
          const imageUrl = yarns[yarn][node][color]
          textureUrls.push(imageUrl)
        }
      }
    }

    const wrapper = new SketchfabWrapper({
      iframeQuery: '#apiFrame',
      uid,
      options: iframeOptions,
      useSingleton: false,
      texturesPreloadUrls: textureUrls,
    })

    wrapper.init().then((combinedApi) => {
      this.api = combinedApi // Hack to use native sketchfab api inline with wrapper methords
      onApiLoaded(this.api)
      window.api = this.api // for easy dev testing from browser console

      this.setState({
        sketchfabLoaded: true,
      }, () => this.updateTextures(materials))
      this.api.addEventListener('click', (event) => {
        if (event.material) {
          if (model.id === 'lynx') {
            const part = event.material.name.split('_') && event.material.name.split('_')[0]
            if (part) {
              this.api.gotoAnnotation(this.api.annotations.map((a) => a.name).indexOf(part))
            }
            if (textures[model.id].namesByPart(event.material.name))
              onMaterialSelect(textures[model.id].namesByPart(event.material.name))
          } else {
            if (event.material.name === 'neck' && model.id === 'indus') {
              this.api.gotoAnnotation(this.api.annotations.map((a) => a.name).indexOf('body'))
              onMaterialSelect('body')
            } else {
              this.api.gotoAnnotation(this.api.annotations.map((a) => a.name).indexOf(event.material.name))
              onMaterialSelect(event.material.name)
            }
          }
        }
      })
    })

    window.addEventListener('resize', () => this.updateIframeSize())
  }

  componentDidUpdate(prevProps) {
    const { materials, selectedTarget, model } = this.props
    const { materials: oldMaterials, selectedTarget: oldTarget } = prevProps

    if (materials.key && materials.key !== oldMaterials.key) {
      this.updateTextures(materials)
    }

    if (selectedTarget !== oldTarget) {
      if (model.id === 'lynx') {
        const part = selectedTarget && selectedTarget[0].split('_') && selectedTarget[0].split('_')[0]
        if (part) {
          return this.api.gotoAnnotation(this.api.annotations.map((a) => a.name).indexOf(part))
        }
      } else {
        return this.api.gotoAnnotation(this.api.annotations.map((a) => a.name).indexOf(selectedTarget))
      }
    }

    if (materials.yarn && oldMaterials.yarn && materials.yarn !== oldMaterials.yarn) {
      return this.updateYarn({ yarn: materials.yarn, noding: Object.keys(textures[model.id].yarns[materials.yarn])[0] })
    }

    if (materials.partToPaint && JSON.stringify(materials) !== JSON.stringify(oldMaterials)) {
      const { yarn, parts } = materials
      const { [materials.partToPaint]: part } = parts
      console.log(yarn, part)
      return this.api.setTexture({
        url: textures[model.id].yarns[yarn][part.noding][part.color],
        target: materials.partToPaint,
        assets_dir: '',
        domain: '',
        texCoordUnit: part.texCoordUnit,
      })
    }
  }

  updateYarn = ({ noding, yarn }) => {
    const { model } = this.props
    if (this.api && model.id !== 'lynx') {
      this.bodyParts.forEach((part) => {
        var y = this.api._getMaterialByName(part)
        if (y) {
          y.channels.SubsurfaceScattering.factor = yarn === 'cashmere' ? 7 : 3
          this.api.setMaterial(y)
        }
        // const color = textures[model.id].defaultColors[yarn]
        if (model.id === 'indus') {
          const partNoding = textures[model.id].defaultState.parts[part].noding
          this.api.setTextures({
            pairs: [
              {
                url: textures[model.id].yarns[yarn][partNoding].cavity,
                channel: 'CavityPBR',
              },
              {
                url: textures[model.id].yarns[yarn][partNoding].normal,
                channel: 'NormalMap',
              },
              // {
              //   url: textures[model.id].yarns[yarn][noding][color],
              //   channel: 'AlbedoPBR',
              // },
            ],
            target: part,
            assets_dir: '',
            domain: '',
            texCoordUnit: part.includes('sleeve') ? 1 : 0,
          })
        }
      })
    }
  }

  updateTextures = (config) => {
    const { model } = this.props
    if (this.api) {
      if (model.id === 'lynx') {
        const { yarn = 'cashmere', parts } = config
        const rules = []
        Object.keys(parts).forEach((k, i) => {
          if (this.bodyParts.includes(k)) {
            rules.push({
              name: k,
              noding: parts[k].noding || model.defaultNoding,
              color: parts[k].color || model.defaultColor,
            })
          }
        })
        rules.forEach((r) => {
          if (r.noding && r.color && r.name) {
            const pairs = [
              {
                url: textures[model.id].yarns[yarn][r.noding][r.color],
                channel: 'AlbedoPBR',
              },
            ]
            if (textures[model.id].yarns[yarn][r.noding].normal) {
              pairs.push({
                url: textures[model.id].yarns[yarn][r.noding].normal,
                channel: 'NormalMap',
              })
            }
            if (textures[model.id].yarns[yarn][r.noding].op) {
              pairs.push({
                url: textures[model.id].yarns[yarn][r.noding].op,
                channel: 'CavityPBR',
              })
            }
            console.log(r.name, pairs)
            this.api.setTextures({
              pairs,
              target: r.name,
              assets_dir: '',
              domain: '',
            })
          }
        })
      } else {
        const { yarn = 'cashmere', parts } = config
        const rules = []
        Object.keys(parts).forEach((k, i) => {
          if (this.bodyParts.includes(k)) {
            rules.push({
              name: k,
              noding: parts[k].noding || model.defaultNoding,
              color: parts[k].color || model.defaultColor,
              texCoordUnit: parts[k].texCoordUnit,
            })
          }
        })
        rules.forEach(
          (r) =>
            r.noding &&
            r.color &&
            r.name &&
            this.api.setTexture({
              url: textures[model.id].yarns[yarn][r.noding][r.color],
              target: r.name,
              assets_dir: '',
              domain: '',
              texCoordUnit: r.texCoordUnit,
            })
        )
      }
    }
  }

  updateIframeSize = () => {
    this.setState({
      iframeHeight: window.innerHeight - (window.innerWidth > 768 ? 100 : 225),
      iframeWidth: Math.min(window.innerWidth, 1200),
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateIframeSize())
  }

  render() {
    const { sketchfabLoaded, uid, iframeHeight, iframeWidth } = this.state
    return (
      <Container>
        <iframe
          // style={{ opacity: sketchfabLoaded ? 1 : 0, zIndex: model.zIndex }}
          title={uid}
          id="apiFrame"
          height={iframeHeight}
          width={iframeWidth}
          className="iframe"
          style={{
            opacity: sketchfabLoaded ? 1 : 0,
          }}
        />
      </Container>
    )
  }
}

Model.propTypes = {}

export default Model
